import { useState } from "react";
import IsUserPremium from "./isUserPremium";

export default function UsePremiumStatus() {

    const [premiumStatus, setPremiumStatus] = useState();
    IsUserPremium().then((r) => setPremiumStatus(r));

    return premiumStatus;
}
