import { db, messaging } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";

if('serviceWorker' in navigator) {
    navigator.serviceWorker.register("firebase-messaging-sw.js")
    .then(function(reg) {
        console.log("Service worker registered")
    }).catch(function(err) {
        console.log(err);
    });
}

export async function requestNotificationPermission(uid) {
    console.log("Requesting permission");
    const permission = await Notification.requestPermission();

    if(permission === "granted") {
        console.log("you have a permission");
        saveMessagingDeviceToken(uid);
    } else{
        alert("We don't have a permission to send you a notification. Please allow us to send you notification to get alerts.")
    }
}

export async function saveMessagingDeviceToken(uid) {
    const msg = await messaging()
    const fcmToken = await getToken(msg, {vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY})
    
    if(fcmToken) {
        console.log("writing fcm");
        await setDoc(doc(db, "users/"+ uid +"/fcm-tokens", "fcmToken"), {
            token: fcmToken,
          });
    }

    onMessage(msg, (message) => {
        console.log(
          'New foreground notification from Firebase Messaging!',
          message.notification
        );
        navigator.serviceWorker.ready.then((registration) => { registration.showNotification(message.notification.title, { body: message.notification.body });})
      });
}