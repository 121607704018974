import React from 'react'
import './App.css'
import './assets/global.css'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PrivateRoutes from './components/PrivateRoute'
import Home from './pages/Home'
import Signup from './pages/Signup'
import Login from './pages/Login'
import PasswordReset from './pages/PasswordReset'
import Profile from './pages/Profile'
import MapSetting from './pages/MapSetting'
import Payment from './pages/Payment'
import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (

    <ChakraProvider>
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Home />} path="/" />
              <Route element={<Profile />} path="/profile" />
              <Route element={<MapSetting />} path="/mapsetting" />
              <Route element={<Payment />} path="/payment" />
            </Route>
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/reset' element={<PasswordReset />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
