import React, { useEffect, useRef, useState } from "react"
import Nav from '../components/NavBar/Nav'
import image from '../assets/towingImg.png'
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {


  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [loading, setLoading] =useState()
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  useEffect(() => {
    if(currentUser != null) {
      navigate("/")}
  }, []);

  function handleSubmit(e) {
    e.preventDefault()
      setLoading(true)
    login(emailRef.current.value, passwordRef.current.value)
    setLoading(false)
  }

  return (
    <div className='homeScreen flex flex-col h-screen bg-gray-300'>
      <Nav />

      <div className='flex h-full'>
        <div className='flex bg-white border-2 border-gray-400 m-auto rounded-md'>
          <div className='flex flex-col h-[32rem] w-80 my-auto ml-auto justify-between p-4'>
            <form onSubmit={handleSubmit}>
              <div className='mt-4'>
                <label className='text-violet-500 font-bold' htmlFor="email">Email</label> <br />
                <input
                  className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 rounded-md p-1'
                  type="email"
                  id="email"
                  placeholder='Please enter your e-mail address'
                  ref={emailRef}
                  required
                />
              </div>
              <div className='mt-4'>
                <label className='text-violet-500 font-bold' htmlFor="password">Password</label> <br />
                <input
                  className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 p-1 rounded-md'
                  type="password"
                  id="password"
                  placeholder='Please enter your password'
                  ref={passwordRef}
                  required
                />
              </div>
              <button disabled={loading} className='bg-violet-500 h-12 w-full font-bold mt-16 text-white rounded-md' type="submit">Login</button>
            </form>
            <div className='flex flex-col items-center'>
              <p>Need an account?</p>
              <p className=" text-violet-500 font-medium"><Link to='/signup'>Sign up </Link></p>
              <p>Forgot your password</p>
              <p className=" text-violet-500 font-medium"><Link to='/reset'>Reset My Password</Link></p>
            </div>
          </div>
          <div className='my-auto mr-auto justify-between hidden lg:block'>
            <img className='rounded-r-md object-cover h-[32rem] w-[40rem]' src={image} alt='Accident Logo' />
          </div>
        </div>
      </div>
    </div>
  )

}