import { useAuth } from "../AuthContext";
    


export default async function IsUserPremium() {


    const { currentUser } = useAuth();

    await currentUser?.getIdToken(true);
    const decodedToken = await currentUser?.getIdTokenResult();

    return decodedToken?.claims?.stripeRole ? true : false;
}