import { db } from "../../firebase";
import { doc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import getStripe from "./initializeStripe"




export default async function createCheckoutSession(currentUser) {
    let docId;
    await addDoc(collection(db, "users/" + currentUser.uid + "/checkout_sessions"), {
        price: process.env.REACT_APP_STRIPE_PRICE,
        success_url: window.location.origin + "/",
        cancel_url: window.location.origin + "/payment",
    })
        .then(async (value) => {
            console.log("Document written with ID: ", value.id);
            docId = value.id;
        }
        )
        .catch((error) => alert(`An error occured: ${error.message}`));

        onSnapshot(doc(db, "users/" + currentUser.uid + "/checkout_sessions", docId), async (doc) => {
            const { error, sessionId } = doc.data()

            if (error) {
                alert(`An error occured: ${error.message}`);
            }

            if (sessionId) {
                const stripe = await getStripe()                
                stripe.redirectToCheckout({ sessionId })
            }
        });
}