import React, { useEffect, useState } from "react"
import Nav from '../components/NavBar/Nav'
import { GoogleMap, useJsApiLoader, MarkerF, CircleF } from '@react-google-maps/api';
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import * as Slider from '@radix-ui/react-slider';
import useDoc from '../hooks/useDoc'
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getBoundsOfDistance, getCenterOfBounds } from 'geolib';
import { useToast } from '@chakra-ui/react'



function MapSetting() {


    const [loading, setLoading] = useState()
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [center, setCenter] = useState({ lat: 40.785, lng: -73.965 }) //NY Central Park
    const [radius, setRadius] = useState(12070)
    const toast = useToast()
    const MILES_TO_KILOS = 1.60934;
    const DISTANCE_BTWN_LATITUDE = 111319.499999999;
    //When user sign up we set the initial coordinates out of the range to test if the user set the area.
    //If the top value is 91 we will force the user to go map setting page.
    const INITIAL_TOP_COORDINATE = 91;

    const userInfo = useDoc("users", currentUser.uid);

    useEffect(() => {
        if (userInfo.data.length !== 0) {
            if (userInfo.data.box.top !== INITIAL_TOP_COORDINATE) {
                const tempCenter = getCenterOfBounds([
                    { latitude: userInfo.data.box.top, longitude: userInfo.data.box.left },
                    { latitude: userInfo.data.box.bottom, longitude: userInfo.data.box.left },
                    { latitude: userInfo.data.box.top, longitude: userInfo.data.box.right },
                    { latitude: userInfo.data.box.bottom, longitude: userInfo.data.box.right },
                ])
                setCenter({
                    lat: tempCenter.latitude,
                    lng: tempCenter.longitude
                })
                setRadius(Math.round((((userInfo.data.box.top - userInfo.data.box.bottom) / 2) * DISTANCE_BTWN_LATITUDE)))
            }
        }
    }, [userInfo.data.length, userInfo.data.box])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    async function setBBox(e) {
        e.preventDefault()
            setLoading(true)
            const userRef = doc(db, "users", currentUser.uid);
            const coordinates = getBoundsOfDistance({ latitude: center.lat, longitude: center.lng }, radius)
            await updateDoc(userRef, {
                box: {
                    bottom: coordinates[0].latitude,
                    left: coordinates[0].longitude,
                    top: coordinates[1].latitude,
                    right: coordinates[1].longitude
                }
            }).then(() => {
                toast({
                    title: "You successfully created an account.",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })
                navigate('/')
            })
            .catch(
                (error) =>
                toast({
                title: error.code,
                status: 'error',
                duration: 4000,
                isClosable: true,
            }));

        setLoading(false)
    }

    return (
        <div className='flex flex-col w-full min-w-[22rem] h-screen bg-gray-300'>
            <Nav />
            <div className='flex flex-grow-1 justify-center items-center w-full h-full p-4'>
                <div className="bg-white border-2 border-gray-400 rounded-xl flex flex-col md:flex-row w-full h-[55rem] md:h-full  max-h-[56rem] min-h-[29rem] max-w-[90rem] p-2">
                    <div className='flex flex-col w-full  md:w-2/3 p-2'>
                        <div className=' w-full h-[95%] min-h-[24rem] '>
                            {isLoaded ?
                                <GoogleMap
                                    mapContainerStyle={{ height: '100%', width: 'auto' }}
                                    center={center}
                                    zoom={10}
                                    onClick={(c) => setCenter(c.latLng.toJSON())}
                                >
                                    <MarkerF
                                        position={center}

                                    />
                                    <CircleF
                                        center={center}
                                        radius={radius}
                                        options={{
                                          strokeColor: '#8b5cf6',
                                          fillOpacity: 0.2,
                                          fillColor: '#8b5cf6'
                                        }}
                                        onClick={(c) => setCenter(c.latLng.toJSON())}
                                    />
                                </GoogleMap>
                                :
                                <p>Map is loading...</p>
                            }
                        </div>
                        <div className="w-full h-[10%] min-h-[30px] mt-2 px-2 border-2 rounded-2xl border-gray-300 overflow-hidden">
                            <Slider.Root
                                className="relative flex items-center select-none touch-none h-full"
                                value={[radius]}
                                max={12070}
                                step={1}
                                onValueChange={(value) => setRadius(value[0])}
                                aria-label="Volume"
                            >
                                <Slider.Track className=" bg-violet-300 relative grow rounded-full h-[3px]">
                                    <Slider.Range className="absolute bg-violet-500 rounded-full h-full" />
                                </Slider.Track>

                                <Slider.Thumb
                                    className="block w-24 h-5 bg-white shadow-[0_2px_10px] rounded-[10px] 
                                hover:bg-violet3 focus:outline-none hover:shadow-[0_0_10px_1px] hover:shadow-violet-500 text-violet-500 text-center">
                                    {parseFloat(radius / 1000 / MILES_TO_KILOS * 2).toFixed(2)} Miles
                                </Slider.Thumb>

                            </Slider.Root>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between w-full md:w-1/3 p-2 mt-10'>
                        <div className="my-auto">
                            <h1 className="text-3xl font-bold my-auto">How to Choose Area</h1>
                            <p className="mt-10">
                            To select the area where you want to receive notifications, touch a point on the map and adjust the size of the area using the slider below.
                            </p>
                        </div>
                        <div>
                            <button onClick={setBBox} disabled={loading} className='bg-violet-500 h-10 w-full font-bold mt-8  text-white rounded-md' type="submit">Save</button>
                            <button disabled={loading} className='bg-violet-500 h-10 w-full font-bold mt-8  text-white rounded-md' type="submit" onClick={() => navigate('/profile')}> Back to Profile Settings</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default MapSetting