import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const useDoc = (colRef, docRef) => {
    const [isloading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const subscriber = onSnapshot(doc(db, colRef, docRef), (doc) => {
          // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          setData(doc.data())
          setIsLoading(false)
        });


        return () => subscriber();

    }, [colRef, docRef]);

    return { isloading, data }
};

export default useDoc;