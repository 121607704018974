import React, { useContext, useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc } from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const toast = useToast()
    const navigate = useNavigate()


    async function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                sendEmailVerification(userCredential.user)
                setCurrentUser(userCredential.user)
                const user = userCredential.user;
                setDoc(doc(db, "users", user.uid), {
                    uuid: user.uid,
                    displayName: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    paidTill: "",
                    box: {
                        top: 91,
                        bottom: 91,
                        left: 181,
                        right: 181
                    },
                    notifications: true,
                    emailNotify: true,
                });

                setDoc(doc(db, "users", user.uid, "alerts", "test"), {

                });

                setDoc(doc(db, "users", user.uid, "fcm-tokens", "test"), {

                });
                toast({
                    title: "You successfully created an account.",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })
                return true
            })
            .catch((error) => {
                toast({
                    title: error.code,
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                })
                return false
            });

    }

    function login(email, password) {
        signInWithEmailAndPassword(auth, email, password).then(() => {
            navigate(-1);
          })
          .catch((error) => {

            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            toast({
                description: errorCode,
                status: 'error',
                duration: 4000,
                isClosable: true,
            })

            return false;
        })
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        sendPasswordResetEmail(auth, email).then(() => {
            toast({
                title: "We sent you an email to reset your password. Please check your inbox.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
         }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            toast({
                description: errorCode,
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return false
        })
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}