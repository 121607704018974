import React from 'react'
import logo from '../../assets/logo.png'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/react'


function Nav() {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const { logout } = useAuth()


    return (
        <div className='flex justify-between p-2'>
            <div className='flex flex-row my-auto' onClick={() => navigate('/')}>
                <img
                    src={logo}
                    alt='Accident Logo'
                />
                <h1 className='text-3xl font-bold my-auto'>Accidents</h1>
            </div>

            {currentUser &&
                <div className='flex flex-row my-auto'>
                    <>
                        <button ref={btnRef} onClick={onOpen} className='text-xl font-bold mr-2 text-violet-500'>Menu</button>
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}
                            finalFocusRef={btnRef}
                        >
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader className='bg-violet-500 text-white'>Accidents Menu</DrawerHeader>
                                <DrawerBody className='flex flex-col items-end mr-2'>
                                    <button onClick={() => navigate('/')} className='mt-2'>Home</button>
                                    <button onClick={() => navigate('/mapsetting')} className='mt-2'>Map Settings</button>
                                    <button onClick={() => navigate('/profile')} className='mt-2'>Profile Settings</button>
                                    <button onClick={() => logout()} className='mt-2'>Logout</button>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </>
                </div>
            }
        </div>
    )
}

export default Nav