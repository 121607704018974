import React, { useState } from 'react'
import Nav from '../components/NavBar/Nav'
import { useAuth } from "../contexts/AuthContext";
import CreateCheckoutSession from '../contexts/stripe/createCheckoutSession';
import usePremiumStatus from '../contexts/stripe/usePremiumStatus';
import { Spinner } from '@chakra-ui/react'

export default function Payment() {
    const { currentUser } = useAuth();
    const [button, setButton] = useState(true)
    const userIsPremium = usePremiumStatus();
    // const userIsPremium = false;

    function handleCkeckOut() {
        setButton(false);
        CreateCheckoutSession(currentUser);
    }

    return (
        <div className='flex flex-col w-full min-w-[22rem] h-full min-h-screen bg-gray-300'>
            <Nav />
            <div className='flex flex-grow-1 justify-center w-full  m-auto p-4'>
                <div className="bg-white border-2 border-gray-400 rounded-xl flex flex-col w-full max-w-[60rem]  p-4">
                    <div className='w-full p-2'>
                        {userIsPremium ?
                            <>
                                <div className='flex mt-2'>
                                    <p className='text-violet-500 font-bold' htmlFor="name">Subscription:</p>
                                    <p className='text-green-500 font-bold ml-2' htmlFor="name">Active</p>
                                </div>
                                <div>
                                <button
                                    className='flex justify-center items-center m-auto  bg-violet-500 disabled:bg-violet-300 h-12 w-full max-w-[21rem]  font-bold  text-white rounded-md'
                                    onClick={() =>
                                        { currentUser?
                                            window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK + "?prefilled_email="+currentUser.email, "blank")
                                            :
                                            window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK, "blank")                                        
                                        }}
                                        >
                                    Manage the subscription!
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <button
                                    disabled={!button}
                                    className='flex justify-center items-center m-auto  bg-violet-500 disabled:bg-violet-300 h-12 w-full max-w-[21rem]  font-bold  text-white rounded-md'
                                    onClick={() => handleCkeckOut() }
                                    >
                                    {button? <>Subscribe</> : <Spinner />}
                                    </button>
                                </div>
                            </>
                        }



                    </div>
                </div>
            </div>
        </div>

    )
}
