import React, { useEffect, useState } from 'react'
import Nav from '../components/NavBar/Nav'
import { GoogleMap, useJsApiLoader, MarkerF, CircleF } from '@react-google-maps/api';
import { useAuth } from '../contexts/AuthContext'
import useCollections from '../hooks/useCollections'
import useDoc from '../hooks/useDoc'
import { useNavigate } from 'react-router-dom';
import { getCenterOfBounds } from 'geolib';
import CreateCheckoutSession from '../contexts/stripe/createCheckoutSession';
import usePremiumStatus from '../contexts/stripe/usePremiumStatus';
import { Spinner } from '@chakra-ui/react'
import { requestNotificationPermission } from '../contexts/NotificationHandler';



function Home() {
  const { currentUser } = useAuth()
  const userIsPremium = usePremiumStatus();
  const [button, setButton] = useState(true)

  const accidentCollections = useCollections("users/" + currentUser.uid + "/alerts");
  const userInfo = useDoc("users", currentUser.uid);
  const navigate = useNavigate()
  const DISTANCE_BTWN_LATITUDE = 111319.499999999;
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [centerMarker, setCenterMarker] = useState()
  const [centerCircle, setCenterCircle] = useState()
  const [radiusCircle, setRadiusCirxle] = useState()
  const { logout } = useAuth()
  //When user sign up we set the initial coordinates out of the range to test if the user set the area.
  //If the top value is 91 we will force the user to go map setting page.
  const INITIAL_TOP_COORDINATE = 91;

  

  useEffect(() => {
    requestNotificationPermission(currentUser.uid);
  }, [currentUser.uid]);

  useEffect(() => {
    if (!currentUser.emailVerified) {
      alert("Please verify your email address");
      logout()
    }
  }, [currentUser, logout])

  useEffect(() => {
    if (userInfo.data.length !== 0) {
      if (userInfo.data.box.top === 91) {
        navigate("/mapsetting")
      }
      if (userInfo.data.box.top !== INITIAL_TOP_COORDINATE) {
        const tempCenter = getCenterOfBounds([
          { latitude: userInfo.data.box.top, longitude: userInfo.data.box.left },
          { latitude: userInfo.data.box.bottom, longitude: userInfo.data.box.left },
          { latitude: userInfo.data.box.top, longitude: userInfo.data.box.right },
          { latitude: userInfo.data.box.bottom, longitude: userInfo.data.box.right },
        ])
        setCenter({
          lat: tempCenter.latitude,
          lng: tempCenter.longitude
        })
        setCenterCircle({
          lat: tempCenter.latitude,
          lng: tempCenter.longitude
        })
        setRadiusCirxle(Math.round((((userInfo.data.box.top - userInfo.data.box.bottom) / 2) * DISTANCE_BTWN_LATITUDE)))
      }
    }
  }, [userInfo.data, navigate]
  );



  // const [showInfoWindow, setShowInfoWindow] = useState(false);
  // const [info, setInfo] = useState({});


  useEffect(() => {
    if (accidentCollections.data.sort((a, b) => (a.pubMillis > b.pubMillis ? -1 : 1))[0] !== undefined) {
      setCenterMarker({
        lat: accidentCollections.data.sort((a, b) => (a.pubMillis > b.pubMillis ? -1 : 1))[0].location.y,
        lng: accidentCollections.data.sort((a, b) => (a.pubMillis > b.pubMillis ? -1 : 1))[0].location.x
      })
    }
  }, [accidentCollections.data])

  const centerHandler = (x, y, info) => {
    // setInfo({
    //   date: info.pubMillis,
    //   impact: info.subtype
    // })
    setCenterMarker({
      lng: x,
      lat: y
    });
  }

  function handleCkeckOut() {
    setButton(false);
    CreateCheckoutSession(currentUser);
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  // const [map, setMap] = React.useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   map.setCenter(center);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  return (
    <div className='flex flex-col w-screen h-screen bg-gray-300'>
      <Nav />
      {userIsPremium ?
        <>
          <div className='flex flex-col-reverse justify-center items-center lg:flex-row-reverse w-full lg:h-full'>
            <div className='bg-white border-y-2 border-r-2 border-gray-400 md:rounded-r-xl w-full h-full lg:max-w-[36rem] max-h-[48rem]   min-w-[24rem] min-h-[24rem] overflow-auto scrollbar '>
              <h1 className='flex justify-center text-2xl border-b-2 py-2'>Alerts List</h1>
              <ul>
                {accidentCollections.isloading ? <p>Loading...</p> : <>{accidentCollections.data.length === 0 ? <p className='bg-red-300 flex justify-center'>Accidents will apear here!!! <br /> There is no accidents in your area yet.</p>
                :
                //This line of code filters older alerts
                accidentCollections.data.filter(item => item.pubMillis > (Date.now() - 43200000)).sort((a, b) => (a.pubMillis > b.pubMillis ? -1 : 1)).map((item, i) => (
                  <div key={item?.uuid} className='flex flex-row justify-between border-y-4  p-4 group/item hover:bg-slate-200 '>
                    <div>
                      <li onClick={() => centerHandler(item.location.x, item.location.y, item)} className=''>
                        <p>Accident Date: {new Date(item.pubMillis).toLocaleString()}</p>
                        {item.city != null && <p>City: {item.city}</p>}
                        {item.street != null && <p>Street: {item.street}</p>}
                        {item.subtype.includes("MINOR") && <p>Impact: Minor</p>}
                        {item.subtype.includes("MAJOR") && <p>Impact: Major</p>}
                      </li>
                    </div>
                    <div className='bg-violet-500 h-10 font-bold text-white rounded-md p-2 my-auto hover:bg-violet-600'>
                      <button onClick={() => window.open("https://maps.google.com/?q=" + item.location.y + "," + item.location.x, "blank")} type="submit">Navigate</button>
                    </div>
                  </div>
                ))} </>}
              </ul>
            </div>
            <div className='bg-white border-y-2 border-l-2 border-gray-400 md:rounded-l-xl w-full h-full max-w-[60rem] max-h-[24rem] md:max-h-[48rem]  min-w-[24rem] min-h-[24rem]'>
              {isLoaded ?
                <GoogleMap
                  mapContainerStyle={{ height: '100%', width: 'auto', borderRadius: '0.75rem' }}
                  center={center}
                  zoom={11}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
                >
                  <MarkerF
                    position={centerMarker}
                    title=''
                    onClick={() => window.open("https://maps.google.com/?q=" + centerMarker.lat + "," + centerMarker.lng, "blank")}
                  // onMouseOver={() => setShowInfoWindow(true)}
                  />
                  <CircleF
                    fillOpacity={0.9}
                    center={centerCircle}
                    radius={radiusCircle}
                    options={{
                      strokeColor: '#8b5cf6',
                      fillOpacity: 0.2,
                      fillColor: '#8b5cf6'
                    }}
                  />

                </GoogleMap>
                :
                <p>Map is loading...</p>
              }
            </div>
          </div>
        </>
        :
        <>
          <div className='flex flex-col justify-center items-center m-auto'>
            <p>You don't have access to see accidents. Please Subscripe our service first!</p>
            <button
              disabled={!button}
              className='flex justify-center items-center m-auto  bg-violet-500 disabled:bg-violet-300 h-12 w-full max-w-[21rem]  font-bold  text-white rounded-md'
              onClick={() => handleCkeckOut()}
            >
              {button ? <>Subscribe</> : <Spinner />}
            </button>
          </div>
        </>
      }

    </div>
  )
}

export default Home