import React, { useEffect, useState } from "react"
import Nav from '../components/NavBar/Nav'
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import useDoc from '../hooks/useDoc'
import * as Switch from '@radix-ui/react-switch';
import { doc, updateDoc } from "firebase/firestore";
import { updateEmail, updateProfile, updatePassword } from "firebase/auth";
import { db } from "../firebase";
import { useToast } from '@chakra-ui/react'

function Profile() {

    const { logout } = useAuth()
    const [loading, setLoading] = useState()
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [emailConfirm, setEmailConfirm] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [getNotification, setNotification] = useState(true)
    const [getEmailNotification, setEmailNotification] = useState(true)
    const { currentUser } = useAuth()
    const toast = useToast()
    const userInfo = useDoc("users", currentUser.uid);

    useEffect(() => {
        if (userInfo.data.length !== 0) {
            if (userInfo.data.box.top === 91) {
                navigate("/mapsetting")
            }
        }
    })

    useEffect(() => {
        if (userInfo.data.length !== 0) {
            //If there is empty field in database it cause the variables state to change string to null.
            //Since form input can not get null value I put an empty string if the field is null.
            setName(currentUser.displayName !== null ? currentUser.displayName : '')
            setEmail(currentUser.email)
            setPhone(userInfo.data.phoneNumber !== null ? userInfo.data.phoneNumber : '')
            setNotification(userInfo.data.notifications)
            setEmailNotification(userInfo.data.emailNotify)
        }
    }, [userInfo.data, currentUser]);

    async function submitProfile(e) {
        e.preventDefault()
            setLoading(true)
            const userRef = doc(db, "users", currentUser.uid);
            await updateDoc(userRef, {
                displayName: name,
                phoneNumber: phone,
                emailNotify: getEmailNotification,
                notifications: getNotification
            }).then(() => 
            toast({
                title: "You successfully saved your profile",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })).catch(
                (error) =>
                toast({
                title: error.code,
                status: 'error',
                duration: 4000,
                isClosable: true,
            }));

            await updateProfile(currentUser, { displayName: name })
            .catch(
                (error) =>
                toast({
                title: error.code,
                status: 'error',
                duration: 4000,
                isClosable: true,
            }));

        setLoading(false)
    }

    async function submitPassword(e) {
        e.preventDefault()
        setLoading(true)
        updatePassword(currentUser, password).then(() => {
            toast({
                title: "You successfully updated your password",
                description: "Please logn with your new credentials",
                status: 'success',
                duration: 7000,
                isClosable: true,
            })
            handleLogout()
        }).catch(
            (error) =>
            toast({
            title: error.code,
            status: 'error',
            duration: 4000,
            isClosable: true,
        }));
        setLoading(false)
    }

    async function submitEmail(e) {
        e.preventDefault()
        setLoading(true)
        updateEmail(currentUser, email).then(() => {
            toast({
                title: "You successfully updated your email address.",
                description: "Please logn with your new credentials",
                status: 'success',
                duration: 7000,
                isClosable: true,
            })
            handleLogout()
        }).catch(
            (error) =>
            toast({
            title: error.code,
            status: 'error',
            duration: 4000,
            isClosable: true,
        }));
        setLoading(false)
    }

    async function handleLogout() {
        try {
            await logout()
            navigate("/")
        } catch (e) {
            console.log(e);
        }
    }

    function inputMatchCheck(elementId) {
        var input = document.getElementById(elementId + 'Confirm');
        if (input.value !== document.getElementById(elementId).value) {
            input.setCustomValidity(elementId + ' must be matching.');
        } else {
            // input is valid -- reset the error message
            input.setCustomValidity('');
        }
    }

    return (
        <div className='flex flex-col w-full min-w-[22rem] h-full min-h-screen bg-gray-300'>
            <Nav />
            <div className='flex flex-grow-1 justify-center w-full  m-auto p-4'>
                <div className="bg-white border-2 border-gray-400 rounded-xl flex flex-col w-full max-w-[60rem]  p-4">
                    <div className='w-full p-2'>
                        <form onSubmit={submitProfile}>
                            <div className='mt-2'>
                                <label className='text-violet-500 font-bold' htmlFor="name">Name</label> <br />
                                <input
                                    className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 rounded-md p-1'
                                    type="text"
                                    id="name"
                                    placeholder='Please enter your name'
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    required
                                />
                            </div>
                            <div className='mt-4'>
                                <label className='text-violet-500 font-bold' htmlFor="phone">Phone Number</label> <br />
                                <input
                                    className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 rounded-md p-1'
                                    type="tel"
                                    id="phone"
                                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                    maxLength={10}
                                    placeholder='Please enter your phone number'
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex flex-row justify-end mt-2">
                                <div className="mt-4 flex items-center mr-3">
                                    <label className="text-violet-500 font-bold leading-none pr-[15px]" htmlFor="notification">
                                        Notifications
                                    </label>
                                    <Switch.Root
                                        checked={getNotification}
                                        onCheckedChange={(checked) => setNotification(checked)}
                                        className="w-[42px] h-[25px] bg-blackA9 rounded-full relative shadow-[0_2px_10px] shadow-blackA7  data-[state=checked]:bg-violet-500 outline-none cursor-default"
                                        id="notifications"
                                        style={{ 'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)' }}
                                    >
                                        <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_2px_2px] shadow-blackA7 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                                    </Switch.Root>
                                </div>
                                <div className="mt-4 flex items-center">
                                    <label className="text-violet-500 font-bold leading-none pr-[15px]" htmlFor="email">
                                        Emails
                                    </label>
                                    <Switch.Root
                                        checked={getEmailNotification}
                                        onCheckedChange={(checked) => setEmailNotification(checked)}
                                        className="w-[42px] h-[25px] bg-blackA9 rounded-full relative shadow-[0_2px_10px] shadow-blackA7  data-[state=checked]:bg-violet-500 outline-none cursor-default"
                                        id="emails"
                                        style={{ 'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)' }}
                                    >
                                        <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_2px_2px] shadow-blackA7 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                                    </Switch.Root>
                                </div>
                            </div>
                            <button disabled={loading} className='flex justify-center items-center ml-auto  bg-violet-500 h-12 w-full max-w-[21rem]  font-bold mt-10  text-white rounded-md' type="submit">Save</button>
                        </form>
                    </div>
                    <div className='w-full p-2'>
                        <form onSubmit={submitEmail}>
                            <div className=''>
                                <label className='text-violet-500 font-bold' htmlFor="email">Email Address</label> <br />
                                <input
                                    className='h-10 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 rounded-md p-1'
                                    type="email"
                                    id="email"
                                    placeholder='Please enter your e-mail address'
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    required
                                />
                            </div>
                            <div className='mt-2'>
                                <label className='text-violet-500 font-bold' htmlFor="emailConfirm">Confirm Email Address</label> <br />
                                <input
                                    className='h-10 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 rounded-md p-1'
                                    type="email"
                                    id="emailConfirm"
                                    placeholder='Please enter your e-mail address'
                                    value={emailConfirm}
                                    onChange={(event) => setEmailConfirm(event.target.value)}
                                    onInput={() => inputMatchCheck("email")}
                                    required
                                />
                            </div>
                            <button disabled={loading} className='flex justify-center items-center ml-auto  bg-violet-500 h-12 w-full max-w-[21rem]  font-bold mt-10  text-white rounded-md' type="submit">Submit New Email Address</button>
                        </form>
                    </div>
                    <div className='w-full p-2'>
                        <form onSubmit={submitPassword}>
                            <div className='mt-2'>
                                <label className='text-violet-500 font-bold' htmlFor="password">Password</label> <br />
                                <input
                                    className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 p-1 rounded-md'
                                    type="password"
                                    id="password"
                                    placeholder='Please enter your password'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                />
                            </div>
                            <div className='mt-4'>
                                <label className='text-violet-500 font-bold' htmlFor="passwordConfirm">Confirm Password</label> <br />
                                <input
                                    className='h-12 w-full border-2 border-violet-200 outline-2 outline-violet-500 mt-2 p-1 rounded-md'
                                    type="password"
                                    id="passwordConfirm"
                                    placeholder='Please enter your password'
                                    value={passwordConfirm}
                                    onChange={(event) => setPasswordConfirm(event.target.value)}
                                    onInput={() => inputMatchCheck("password")}
                                    required
                                />
                            </div>
                            <button disabled={loading} className='flex justify-center items-center ml-auto  bg-violet-500 h-12 w-full max-w-[21rem]  font-bold mt-10  text-white rounded-md' type="submit">Submit New Password</button>
                        </form>
                    </div>
                    <div className='flex gap-4 p-2'>
                        <button disabled={loading} className='flex justify-center items-center ml-auto  bg-violet-500 h-12 w-full max-w-[21rem]  font-bold mt-2  text-white rounded-md' type="submit" onClick={() => navigate('/mapsetting')}>Map Setting</button>
                        <button disabled={false} className='flex justify-center items-center ml-auto  bg-violet-500 disabled:bg-violet-300 h-12 w-full max-w-[21rem]  font-bold mt-2  text-white rounded-md' type="submit" onClick={() => navigate('/payment')}>Payments</button>
                        <button disabled={loading} className='flex justify-center items-center ml-auto  bg-violet-500 h-12 w-full max-w-[21rem]  font-bold mt-2  text-white rounded-md' type="submit" onClick={() => handleLogout()}>Logout</button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Profile