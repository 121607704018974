import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const useCollections = (collectionPath) => {
    const [isloading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        const collectionRef = collection(db, collectionPath);
        const subscriber = onSnapshot(collectionRef, {
            next: (snapshot) => {
                console.log("UPDATED")
                const data = [];
                snapshot.docs.forEach((doc) => {
                    if (doc.data().uuid !== undefined) {
                        data.push({ key: doc.id, ...doc.data() })
                    }
                });
                setData(data)
                setIsLoading(false)
            },
        });

        return () => subscriber();

    }, [collectionPath]);

    return { isloading, data }
};

export default useCollections;